<template>
  <b-container class="import-wizard-step02 import-wizard-step">
    <b-row no-gutters>
      <b-col cols="12" lg="4" class="sidebar-text detail-text text-left pt-3 pb-1 px-2 pt-md-0 pb-md-2 px-md-2 py-lg-5 px-lg-5">
        <h2 class="text-dark">{{ $t(this.translationPath + 'title') }} </h2>
        <div v-html="$t(translationPath + 'description')"></div>
      </b-col>
      <b-col cols="12" lg="8" class="accounts_wrapper py-3 pl-2 pr-3 pr-md-0 py-lg-5 pl-lg-5 pr-lg-0">
        <h2 v-if="selectedProvider && offsetAccount">
          <b-img :src="selectedProvider.icon" class="bank-icon"></b-img>
          {{ $t(selectedProvider.translation_string_id) }}
        </h2>
        <h2 v-else>{{ $t(translationPath + 'no_provider') }}</h2>
        <loader v-if="isLoaderShown && (selectedProvider || !providers.length)" :loading-text="loaderText" />
        <template v-if="!isChangeBankFormShown && selectedProvider">
          <b-button class="btn btn-sm" variant="primary" @click="isChangeBankFormShown=true">{{ $t(translationPath + 'change_bank') }}</b-button>
        </template>
        <b-form-group
          v-if="isChangeBankFormShown || (!selectedProvider && providers.length)"
          label-class="align-self-top pt-lg-2 pr-md-0 pr-lg-4 text-black"
          label-for="provider"
          label-cols-lg="5"
          label-cols-xl="5"
          label-align-lg="left"
          class="pt-md-4"
          :label="$t(translationPath + 'new_provider')"
        >
          <custom-select
            v-model="selectedProviderOption"
            label="text"
            valueKey="value"
            :options="providerOptions"
            :search-placeholder="$t('common.type_to_filter')"
            @input="onBankChanged"
          />
        </b-form-group>

        <b-alert variant="danger" class="mt-3" :show="Boolean(errorMsg)" v-html="errorMsg"></b-alert>
        <b-alert variant="success" class="mt-3" :show="isConnected" v-html="$t(translationPath + 'connected', { provider: selectedProvider ? $t(selectedProvider.translation_string_id) : '', connection: selectedConnectionName, refreshedAt: selectedConnectionRefreshedAt })"></b-alert>
        <b-button v-if="isConnected && isRefreshConnectionBtnShown" variant="primary" @click="onRefreshConnectionClick">{{ $t('transactions.import.import_tink.step3.refresh_connection') }}</b-button>
        <b-alert variant="warning" class="mt-3" :show="!isActive">
          <p>{{ $t(translationPath + 'not_active', { provider: selectedProvider ? $t(selectedProvider.translation_string_id) : '', connection: selectedConnectionName }) }}</p>
          <b-button variant="primary" @click="activateCurrentConnection()">{{ $t(translationPath + 'activate_connection') }}</b-button>
        </b-alert>

        <template v-if="!isLoaderShown" class="mt-3">
          <b-form-group
            v-if="connections.length > 1"
            label-class="align-self-top pt-lg-3 pr-md-0 pr-lg-4 text-black"
            label-for="connection"
            label-cols-lg="5"
            label-cols-xl="5"
            label-align-lg="left"
            class="pt-md-4"
            :label="$t(translationPath + 'connection_label')"
          >
            <b-form-select
              v-model="selectedConnectionId"
              class="rounded-pill"
              :options="connectionOptions"
              @input="(value) => selectConnection(value)"
            ></b-form-select>
          </b-form-group>
        </template>

        <template v-if="isConnected && connections.length < 2" class="mt-3">
          <b-form-group
            class="pt-4 mt-5"
            label-class="pt-0 pr-4"
            label-cols-lg="5"
            :label="$t(translationPath + 'toggle_advanced')"
            style="border-top: 1px dashed gray;"
          >
            <b-form-checkbox
              switch
              v-model="isAdvancedOptionsShown"
              class="mt-0"
            />
          </b-form-group>
          <template v-if="isAdvancedOptionsShown">
            <h4 style="border-top: none;" class="mt-0 pt-0">{{ $t(translationPath + 'create_new_connection_heading') }}</h4>
            <p>{{ $t(translationPath + 'create_new_connection_description') }}</p>
            <b-button class="btn btn-sm" variant="primary" @click="createNewConnection">{{ $t(translationPath + 'create_new_connection') }}</b-button>
          </template>
        </template>

        <div v-if="selectedProvider && isIframeShown" class="tink-iframe-wrap">
          <b-embed id="bank_iframe" ref="TinkIframe" type="iframe" :src="tinkLink" class="mt-5 tink-iframe" allow-scripts allow-same-origin allow-popups allow-forms allow-popups-to-escape-sandbox allow-top-navigation></b-embed>
        </div>
      </b-col>
    </b-row>

    <refresh-tink-connection-modal
      ref="RefreshTinkConnectionModal"
      :connection="this.selectedConnection"
      @close="onRefreshConnectionHide"
      @hide="onRefreshConnectionHide"
      @refreshed="onConnectionRefreshed"
    />

    <confirm-modal
      ref="ConfirmNextStepWithoutRefresh"
      :yes-caption="$t('common.ok')"
      :no-caption="$t('common.cancel')"
      :title="$t(translationPath + 'confirm_modal.title')"
      :message="$t(translationPath + 'confirm_modal.text', { date: selectedConnectionRefreshedAt })"
      @confirmed="onConfirmNextStepWithoutRefresh"
    />
  </b-container>
</template>

<script>
import axios from 'axios'
import Loader from '@/components/common/Loader'
import { mapState } from 'vuex'
import moment from 'moment'
import Tink from '@/mixins/Tink.js'
import RefreshTinkConnectionModal from '@/components/modals/RefreshTinkConnectionModal'
import ConfirmModal from '@/components/modals/ConfirmModal'
import CustomSelect from '@/components/common/CustomSelect'

export default {
  name: 'ImportTinkWizardStep02',
  components: { Loader, RefreshTinkConnectionModal, ConfirmModal, CustomSelect },
  mixins: [Tink],
  data () {
    return {
      translationPath: 'transactions.import.import_tink.step2.',
      connections: [],
      providers: [],
      selectedProvider: null,
      selectedProviderOption: null,
      selectedConnectionId: null,
      isInProcess: false,
      isChangeBankFormShown: false,
      isIframeShown: false,
      isLoaderShown: true,
      isConnectionsLoaded: false,
      isActive: true,
      loaderText: '',
      isConnected: false, // true - when successfully connected to input provider,
      credentialsAuthorizationCode: null,
      errorMsg: '',
      isAdvancedOptionsShown: false,
      validateRefreshDate: true
    }
  },
  props: {
    offsetAccount: {
      type: Object
    }
  },
  computed: {
    ...mapState('user', ['currentUser']),
    locale () {
      let returnvalue = 'en_US'
      switch (this.currentUser.default_lang) {
        case 'sv':
          returnvalue = 'sv_SE'
          break
        case 'da':
          returnvalue = 'da_DK'
          break
        case 'en':
        default:
          returnvalue = 'en_US'
      }

      return returnvalue
    },
    selectedConnection () {
      let conn = null
      for (const i in this.connections) {
        if (this.connections[i].id === this.selectedConnectionId) {
          conn = this.connections[i]
          break
        }
      }
      return conn
    },
    selectedConnectionName () {
      let name = ''
      for (const i in this.connections) {
        if (this.connections[i].id === this.selectedConnectionId) {
          name = this.connections[i].name
          break
        }
      }
      return name
    },
    selectedConnectionRefreshedAt () {
      let refreshedAt = ''
      for (const i in this.connections) {
        if (this.connections[i].id === this.selectedConnectionId) {
          if (this.connections[i].credentials_refreshed_at) {
            refreshedAt = this.connections[i].credentials_refreshed_at.substring(0, 10)
          }
          break
        }
      }
      return refreshedAt
    },
    isRefreshConnectionBtnShown () {
      let conn = null
      for (const i in this.connections) {
        if (this.connections[i].id === this.selectedConnectionId) {
          conn = this.connections[i]
          break
        }
      }
      if (!conn) {
        return false
      }

      if (((new Date()) - (new Date(conn.credentials_refreshed_at)) > 259200000)) {
        return true
      }
      return false
    },
    connectionOptions () {
      const opts = []
      this.connections.map(connection => {
        const name = connection.name ? connection.name : ''
        opts.push({
          text: name,
          value: connection.id
        })
      })
      return opts
    },
    providerOptions () {
      const opts = []
      this.providers.map(provider => {
        opts.push({
          text: this.$t(provider.translation_string_id),
          value: provider
        })
      })

      opts.sort((a, b) => {
        return a.text > b.text ? 1 : -1
      })
      return opts
    },
    tinkLink () {
      let link = `https://link.tink.com/1.0/credentials/add?client_id=${this.currentUser.tink_client_id}&scope=accounts:read,investments:read,transactions:read,user:read,credentials:read,identity:read&market=SE&locale=${this.locale}&iframe=true&authorization_code=${this.credentialsAuthorizationCode}&redirect_uri=${document.location.origin}/tink-callback`

      if (this.selectedProvider) {
        link += '&input_provider=' + this.selectedProvider.provider_name
      }

      return link
    }
  },
  methods: {
    async loadConnections (id = null, checkConnection = true) {
      this.isConnectionsLoaded = false
      // this.connections = []
      // this.selectedConnectionId = null
      axios.get(`${process.env.VUE_APP_ROOT_API}/tink/provider-connections/${this.selectedProvider.id}`)
        .then(response => {
          this.connections = response.data.data
          this.connections.map(connection => {
            const tmp = connection.name.match(/#.+#/)
            if (tmp !== null) {
              connection.name = connection.name.replace(/#.+#/, this.$t(tmp[0].replaceAll('#', '')))
            }
          })
          if (this.connections.length > 1) {
            this.isLoaderShown = false
          }
          this.selectConnection(id, checkConnection)
        })
        .catch(err => {
          console.error(err)
        })
        .then(() => {
          this.isConnectionsLoaded = true
        })
    },
    loadTinkProviders () {
      axios.get(`${process.env.VUE_APP_ROOT_API}/tink/providers?is_test=${Number(this.currentUser.tink_app !== 'production')}`)
        .then(response => {
          this.providers = response.data.data
          if (this.offsetAccount) {
            this.selectProvider(this.offsetAccount.tink_input_provider)
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    async selectProvider (providerName) {
      this.selectedProvider = null
      this.isConnected = false
      this.errorMsg = ''
      for (const i in this.providers) {
        if (this.providers[i].provider_name === providerName) {
          this.selectedProvider = this.providers[i]
          break
        }
      }
      if (this.selectedProvider) {
        this.loadConnections(this.selectedConnectionId)
      } else {
        this.isLoaderShown = false
        this.isChangeBankFormShown = true
      }
    },
    selectConnection (id = null, checkConnection = true) {
      if (!id && this.connections.length === 1) {
        this.selectedConnectionId = this.connections[0].id
      } else if (!id && this.connections.length > 1) {
        // If many connections - select one which was refreshed recentrly, or select first if they are all closed
        const connectionsSorted = [...this.connections].filter(i => i.credentials_id)
        if (connectionsSorted.length) {
          connectionsSorted.sort((a, b) => a.credentials_refreshed_at < b.credentials_refreshed_at ? 1 : -1)
          this.selectedConnectionId = connectionsSorted[0].id
        } else {
          this.selectedConnectionId = this.connections.id
        }
      } else if (id && this.connections.length) {
        this.selectedConnectionId = id
      } else if (!this.connections.length) {
        this.createNewConnection()
      }

      if (this.selectedConnectionId && checkConnection) {
        this.checkConnection()
      }
    },
    receiveIframeMessage (event) {
      if (event.origin !== 'https://link.tink.com') {
        return false
      }

      const { type, error, additionalInformation } = JSON.parse(event.data)
      if (type !== 'error' && type !== 'none') {
        return false
      }

      this.isIframeShown = false
      if (error) {
        console.error(error)
        this.writeLog(
          'FAILED',
          'Import tink transactions wizard: ' + error.message,
          this.tinkLink,
          this.selectedProvider ? this.selectedProvider.provider_name : '',
          this.selectedProvider ? this.selectedProvider.id : '',
          this.selectedConnectionId
        )
        this.toastError(error.message, this.$t(this.translationPath + 'connect_to_provider_title'))
        return false
      }

      const credentialsId = Object.prototype.hasOwnProperty.call(additionalInformation, 'credentialsId') ? additionalInformation.credentialsId : null
      if (type === 'none' && credentialsId) {
        this.writeLog(
          'OK',
          'Import tink transactions wizard: ' + 'Authenticated. Received credentials id: ' + credentialsId,
          this.tinkLink,
          this.selectedProvider ? this.selectedProvider.provider_name : '',
          this.selectedProvider ? this.selectedProvider.id : '',
          this.selectedConnectionId
        )
        this.saveCredentials(credentialsId, this.selectedConnectionId)
          .then(response => {
            this.writeLog(
              'OK',
              'Import tink transactions wizard: ' + 'Credentials ' + credentialsId + ' are saved to database.',
              this.tinkLink,
              this.selectedProvider ? this.selectedProvider.provider_name : '',
              this.selectedProvider ? this.selectedProvider.id : '',
              this.selectedConnectionId
            )
            this.isConnected = true
            this.errorMsg = ''
            this.$emit('connection-received', { connection: response.connection })
            this.$emit('need-next-tab')
          })
          .catch(err => {
            console.error(err)
            this.writeLog(
              'FAILED',
              'Import tink transactions wizard: ' + 'Credentials ' + credentialsId + ' are not saved to database.',
              this.tinkLink,
              this.selectedProvider ? this.selectedProvider.provider_name : '',
              this.selectedProvider ? this.selectedProvider.id : '',
              this.selectedConnectionId
            )
            this.toastError(this.$t(this.translationPath + 'fail_on_save_credentials'), this.$t(this.translationPath + 'connect_to_provider_title'))
          })
        return true
      }

      return false
    },
    toastError (msg, title) {
      this.errorMsg = this.$t(this.translationPath + 'connect_to_provider_fail', { message: msg })
      /* this.$bvToast.toast(this.$t(this.translationPath + 'connect_to_provider_fail', { message: msg }), {
        title: title,
        variant: 'danger',
        solid: true,
        'auto-hide-delay': 3000
      }) */
    },
    async validate () {
      if (!this.isConnected) {
        return false
      }

      const nowDate = moment()
      const refreshedAtDate = moment(this.selectedConnectionRefreshedAt)

      if (this.validateRefreshDate && nowDate.diff(refreshedAtDate, 'days') >= 7) {
        this.$refs.ConfirmNextStepWithoutRefresh.show()
        return false
      }

      return true
    },
    async onBankChanged () {
      this.isChangeBankFormShown = false
      this.selectedProvider = this.selectedProviderOption.value
      this.loadConnections()
    },
    checkConnection () {
      this.isConnected = false
      this.isLoaderShown = true
      if (!this.selectedProvider || !this.selectedConnectionId) {
        this.isLoaderShown = false
        return false
      }

      const forProvider = this.selectedProvider.provider_name
      this.loaderText = this.$t(this.translationPath + 'checking_connection')
      this.isIframeShown = false
      axios.get(`${process.env.VUE_APP_ROOT_API}/tink/check-connection-established/${this.selectedConnectionId}`)
        .then(response => {
          if (!this.selectedProvider) {
            return true
          }
          if (this.selectedProvider.provider_name === forProvider) {
            const receivedConnection = response.data.data.connection
            const tmp = receivedConnection.name.match(/#.+#/)
            if (tmp !== null) {
              receivedConnection.name = receivedConnection.name.replace(/#.+#/, this.$t(tmp[0].replaceAll('#', '')))
            }

            if (response.data.data.connection.is_active && !response.data.data.is_connected) {
              this.isIframeShown = true
              this.isConnected = false
              this.isActive = true
              this.credentialsAuthorizationCode = response.data.data.credentials_authorization_code
              this.isLoaderShown = false
            } else if (response.data.data.connection.is_active && response.data.data.is_connected) {
              if (this.selectedConnection.tink_credentials_authorization_code && this.selectedConnection.credentials_id) {
                this.loadConnections(this.selectedConnectionId, false)
                  .then(() => {
                    this.isConnected = true
                    this.isActive = true
                    this.isLoaderShown = false
                  })
              } else {
                this.isIframeShown = false
                this.isConnected = true
                this.isActive = true
                this.isLoaderShown = false
              }
            } else if (!response.data.data.connection.is_active) {
              this.isIframeShown = false
              this.isConnected = false
              this.isActive = false
              this.isLoaderShown = false
            }
            this.$emit('connection-received', { connection: receivedConnection })
          }
        })
        .catch(err => {
          console.error(err)
        })
        .then(() => {
          if (!this.selectedProvider) {
            return true
          }
        })
    },
    createNewConnection () {
      if (this.isInProcess) {
        return false
      }

      this.isInProcess = true
      axios.post(`${process.env.VUE_APP_ROOT_API}/tink/save-empty-connection`, { tink_provider_id: this.selectedProvider.id })
        .then(response => {
          this.loadConnections(response.data.data.id)
        })
        .finally(() => {
          this.isInProcess = false
        })
    },
    activateCurrentConnection () {
      axios.put(`${process.env.VUE_APP_ROOT_API}/tink/activate-connections`, { connection_ids: [this.selectedConnectionId] })
        .then(response => {
          // ...
        })
        .catch(err => {
          console.error(err)
        })
        .then(() => {
          this.checkConnection()
        })
    },
    onRefreshConnectionClick () {
      this.$refs.RefreshTinkConnectionModal.$listenEvents()
      this.$refs.RefreshTinkConnectionModal.show()
    },
    onRefreshConnectionHide () {
      this.$refs.RefreshTinkConnectionModal.$removeEvents()
    },
    onConnectionRefreshed (data) {
      this.$refs.RefreshTinkConnectionModal.hide()
      this.$emit('connection-received', { connection: data.connection })
    },
    onConfirmNextStepWithoutRefresh () {
      this.validateRefreshDate = false
      this.$refs.ConfirmNextStepWithoutRefresh.hide()
      this.$emit('need-next-tab')
    }
  },
  created () {
    this.loadTinkProviders()
  },
  watch: {
    offsetAccount: {
      deep: true,
      handler (newAccount) {
        if (newAccount) {
          this.selectProvider(newAccount.tink_input_provider)
        }
      }
    },
    isConnected (newVal) {
      this.$emit('validate-success', this.isConnected)
      this.$emit('connect-status-change', { isConnected: this.isConnected, tinkProvider: this.selectedProvider })
    },
    isChangeBankFormShown () {
      this.errorMsg = ''
    }
  }
}
</script>
